// src/components/PaymentFailed.js
import React from 'react';
import './PaymentFailed.css'; // Import a CSS file for styling if using CSS modules or stylesheets

const PaymentFailed = () => {
  return (
    <div className="failed-container">
      <div className="failed-icon">
        <img src="https://img.icons8.com/color/100/000000/cancel--v1.png" alt="Failed" />
      </div>
      <h1 className='paymentFailedH1'>Payment Failed</h1>
      <p className='paymentFailedP'>Unfortunately, your payment was canceled or failed. Please try again.</p>
      <a href="/" className="btn paymentFailedBtn">Go Back to Home</a>
    </div>
  );
};

export default PaymentFailed;
